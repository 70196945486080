import * as React from "react"
import {Layout} from "../../components/layout"
import {graphql, Link} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"

const IndexPage = (props) => {
    const data = props.data.dataYaml.wurfplanung
    return (
        <>
            <Layout title='Wurfplanung'>
                <main className='container'>
                    <h1 className='mb-5'>Wurfplanung</h1>
                    {/*<GatsbyImage
                        image={data.img.childImageSharp.gatsbyImageData}
                        alt={"Litter Car"}
                        className='shadow'
                        loading='lazy'
                    />
                    <GatsbyImage
            image={data.img.childImageSharp.gatsbyImageData}
            alt={"Anjuly mit Welpen"}
            className='shadow'
            loading='lazy'
          />
          <br />
          <p>
            Ein Traum geht in Erfüllung! Ein Deckruede aus meiner Zucht wird
            Papa. Dies wird ein ganz besonderer Wurf. Für seine ersten Kinder
            habe ich meine sanfte Anjuly gewählt - ein Traumpaar.
          </p>
          <p>
            Am 24.02.23 schenkte uns Anjuly 2 prächtige Rueden und 6 ganz
            bezaubernde cremefarben Hündinnen". Geburtsgewichte liegen zwischen
            410 und 470 g! Anjuly und den Kleinen geht es sehr gut.
          </p>
          <p>
            Mein besonderer Dank geht an Fr. Dr. Wisniewski und Fr. Dr. Lorenzi
            sowie dem ganzen Team des Kleintierzentrums Iffezheim für die
            großartigen Hilfe. DANKE Wir freuen uns auf die kommenden 8 Wochen.
          </p>
          <p>Alle Welpen sind schon vergeben.</p>
          <p>
            Ich freue mich riesig - zusammen mit seiner Besitzerin Vanessa
            Strittmatter - Anjuly ist tragend!!!
          </p>
          <p>Die Welpen werden Ende Februar erwartet.</p>
          <p>
            Falls Sie ersthaftes Interesse an einem Welpen aus dieser Verpaarung
            haben, melden Sie sich gerne bei mir.</p>
          <Pedigree
            father={data.father}
            mother={data.mother}
            useImage={false}
          />
  
  
          <p>Die Babys von Enya und Dex sind da!</p>
          <GatsbyImage
            image={data.img.childImageSharp.gatsbyImageData}
            alt={"Littercard"}
            className='shadow'
            loading='lazy'
          />
          <p>
            für den ersten Wurf von Enya habe ich mir wieder einen ganz
            besonderen Rüden ausgesucht. Ch. Sequins Soroush. Ein traumhafter
            Golden Rüde mit super Gesundheitsergebnissen und Top Nachzuchten. Er
            passt wunderbar zu meiner eleganten Enya und ich hoffe, dass unsere
            Reise im März/April Früchte trägt. Er steht bei Casper Knippenborg
            in den Niederlanden.
          </p>
          <p>Näheres unter <Link to="/zucht/wuerfe/rwurf/">R-Wurf</Link></p>
          {/*<p>
            Falls Sie ernsthaftes Interesse an einem Welpen aus dieser
            Verpaarung haben, melden Sie sich bitte mit einer aussagekräftigen
            E-mail oder rufen Sie mich an.{" "}
            <a href='tel:+4972469418513'>07246-941 851 3</a>
</p> <br/><br/>
                    <p>Für diesen Wurf haben wir uns für den wunderschönen goldfarbenen Rüden von Ulrike Stahl "Wild
                        Pepper Quizzmaster" entschieden. Er passt hervorragend zu meiner sanften Anjuly. Seine
                        Gesundheitsergebnisse und vor allem sein Charm sprechen für sich. Ein Prachtkerl. Die beiden
                        haben sich gleich verstanden. Nun hoffen wir, dass unser Ausflug nach Lennestadt Früchte
                        getragen hat.
                    </p>
                    <p>Vielen lieben Dank Ulrike für Deine Gastfreundschaft und für Philippe. Wir haben uns bei Euch
                        sehr wohl gefühlt.
                    </p>
                    <p>Falls Sie ernsthaftes Interesse an einem Welpen aus diesem Wurf haben, melden Sie sich bitte
                        telefonisch bei mir. <a href={'tel:072469418513'}>07246/9418513</a></p>*/}
                </main>
            </Layout>
        </>
)
}

export const query = graphql`
query WurfplanungPageQuery {
    dataYaml
    {
        wurfplanung
        {
            img
            {
                childImageSharp
                {
                    gatsbyImageData(layout
                :
                    FULL_WIDTH
                )
                }
            }
            father
            {
                normalName
                img
                {
                    childImageSharp
                    {
                        gatsbyImageData(layout
                    :
                        FULL_WIDTH
                    )
                    }
                }
                father
                {
                    father
                    {
                        name
                    }
                    mother
                    {
                        name
                    }
                    name
                }
                mother
                {
                    father
                    {
                        name
                    }
                    mother
                    {
                        name
                    }
                    name
                }
                name
            }
            mother
            {
                normalName
                img
                {
                    childImageSharp
                    {
                        gatsbyImageData(layout
                    :
                        FULL_WIDTH
                    )
                    }
                }
                father
                {
                    father
                    {
                        name
                    }
                    mother
                    {
                        name
                    }
                    name
                }
                name
                mother
                {
                    father
                    {
                        name
                    }
                    mother
                    {
                        name
                    }
                    name
                }
            }
        }
    }
}
`

export default IndexPage
